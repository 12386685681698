<template>
  <v-card>
    <v-card-title>
      Projects
      <v-spacer></v-spacer>
      <v-text-field
        class="pr-4"
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>

      <v-btn to="CreateProjects" color="primary" id="bt1" dark class="mb-2 mt-2 mr-2">Create Project</v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on"
            href="https://redirection.insightzclub.com/status/overview"
            target="_blank"
            color="primary"
          >Check Survey Sync Status</v-btn>
        </template>

        <span>
          Username: melvin.c@insightzclub.com<br>Password: test123*
        </span>
      </v-tooltip>
    </v-card-title>

    <v-data-table :headers="headers" :items="projects" item-key="_id" :search="search">
      <template v-slot:[`item.name`]="{ item }">
        <a :href="'/buyer/configureProject/' + item._id" target="_blank">
          {{ item.name }}
          
          <v-tooltip bottom v-if="item.editAfterSync"> 
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">⚠️</span>
            </template>
            <span>Survey has been editted after project is sync!</span>
          </v-tooltip>
        </a>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <label v-if="item.status === 'C'">Completed</label>
        <label v-if="item.status === 'I'">Not Started</label>
        <label v-if="item.status === 'L'">Live</label>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        {{ $moment(item.created_at).format("DD-MMMM-YYYY HH:mm:ss") }}
      </template>

      <template v-slot:[`item.updated_at`]="{ item }">
        {{ $moment(item.updated_at).format("DD-MMMM-YYYY HH:mm:ss") }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon class="mr-4" v-on="on" small color="black darken-2"
              :disabled="item.status == 'L' || item.status == 'C'"
              @click="makeLive(item._id)"
            >playlist_add_check</v-icon>
          </template>
          <span v-if="item.status == 'C'">Project is Already Closed</span>
          <span v-if="item.status == 'L'">Project is Already Live</span>
          <span v-if="item.status == 'I'">Make Live</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              slot="activator"
              class="mr-4"
              small
              @click="resyncProject(item._id)"
              color="black darken-2"
              :disabled="item.status == 'I' || item.status == 'C'"
            >sync</v-icon>
          </template>
          <span v-if="item.status == 'I'">Project has Not Started Yet</span>
          <span v-if="item.status == 'L'">Re-Sync Project</span>
          <span v-if="item.status == 'C'">Project is Already Closed</span>
        </v-tooltip>

        <v-tooltip v-if="item.survey_tool!='IZC'" top>
          <template v-slot:activator="{ on }">
            <v-icon
              :disabled="item.status == 'I' || item.status == 'C'"
              v-on="on"
              slot="activator"
              class="mr-4"
              small
              @click="reUpload(item._id)"
              color="black darken-2"
            >cloud_upload</v-icon>
          </template>
          <label>Re-Upload Respondents</label>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceBuyer.js";

const service = new RestResource();
export default {
  data() {
    return {
      rowsPerPageItems: [10, 20, 30, 40],
      pagination: {
        rowsPerPage: 10
      },
      dialog: false,
      props: [],
      projects: [],
      search: "",
      headers: [
        { text: "Name", align: "left", sortable: true, value: "name" },
        { text: "Created At", value: "created_at", sortable: true },
        { text: "Updated At", value: "updated_at", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },

  mounted() {
    this.loadProjects();
  },

  methods: {
    projectStatusMapper(status) {
      if (status == "L") return "Live";
      else if (status == "I") return "Not Started";
      else if (status == "C") return "Closed";
      else return "Undefined";
    },

    loadProjects() {
      this.$setLoader();
      service.fetchProjects({
        selectFields: "_id name status created_at updated_at editAfterSync survey_tool",
        sort: { created_at: -1 }
      }).then(r => {
        this.projects = r.data;
        this.$disableLoader();
      });
    },

    resyncProject(projectId) {
      if (confirm("Are you sure you want to resync this project? It will delete all the project configuration.")) {
        service.updateStatus({
          projectId,
          status: "I"
        }).then(() => {
          this.loadProjects();
        });
      }
    },

    makeLive(projectId) {
      if (confirm("Are you sure you want to make live this project?")) {
        service.updateStatus({
          projectId,
          status: "L"
        }).then(() => {
          this.loadProjects();
        });
      }
    },

    reUpload(projectId) {
      if (confirm("Are you sure you want to reupload the respondents")) {
        service.reuploadProject({
          projectId
        }).then(() => {
          this.loadProjects();
        });
      }
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}
</style>
